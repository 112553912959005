import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetActionsByAccount } from "@/api/graphql/actions/agent/hooks.ts";
import { ACTIONS_PER_PAGE } from "@/api/graphql/actions/constants.ts";
import { ActionBO, Agent, Client } from "@/api/graphql/actions/types.ts";
import { StyledIdentity } from "@/components/Accounts/details/styles.ts";
const getUserName = (person: Agent | Client | undefined) =>
  person?.firstName + " " + person?.lastName || "N F";

export const useActionTableData = (accountId: string) => {
  const { t: tActions } = useTranslation("actions");
  const {
    actions,
    isActionsLoading,
    totalActions,
    hasNextPage,
    fetchNextPage,
  } = useGetActionsByAccount({ pageSize: ACTIONS_PER_PAGE, accountId });
  const columns = useMemo(
    () => [
      {
        columnName: tActions("actions.table.body.columns.date"),
        displayedByDefault: true,
      },
      {
        columnName: tActions("actions.table.body.columns.time"),
        displayedByDefault: true,
      },
      {
        columnName: tActions("actions.table.body.columns.action"),
        displayedByDefault: true,
      },
      {
        columnName: tActions("actions.table.body.columns.agent"),
        displayedByDefault: true,
      },
      {
        columnName: tActions("actions.table.body.columns.actionner"),
        displayedByDefault: true,
      },
    ],
    [tActions],
  );
  const rows = useMemo(() => {
    if (!actions) return [];

    return actions.map((action: ActionBO) => ({
      data: {
        date: action.date,
        time: action.time,
        action: action.name,
        agent: (
          <StyledIdentity
            avatar={{
              firstName: action.agent.firstName,
              lastName: action.agent.lastName,
              type: "initials",
            }}
            subtitle={action.agent.igg}
            title={getUserName(action.agent)}
          />
        ),
        client: (
          <StyledIdentity
            avatar={{
              firstName: action.client?.firstName,
              lastName: action.client?.lastName,
              type: "initials",
            }}
            title={getUserName(action.client)}
          />
        ),
      },
      key: action.id,
    }));
  }, [actions]);

  return {
    columns,
    rows,
    totalActions,
    isActionsLoading,
    hasNextPage,
    fetchNextPage,
  };
};
