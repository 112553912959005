import { useInfiniteQuery } from "@tanstack/react-query";

import { getActionsByUser } from "@/api/graphql/actions/user/api.ts";
import { reducePages } from "@/api/graphql/actions/user/mappers.ts";
import { useGetActionsByUserProps } from "@/api/graphql/actions/user/types.ts";
import { queries } from "@/api/graphql/constants.ts";
import { AccountsMsSearchAuditLogsRequestSortField } from "@/api/graphql/types/graphql.ts";
import { getNextPageParam } from "@/helpers/api/getNextPageParam";

export const useGetActionsByUser = ({
  pageSize,
  userId,
}: useGetActionsByUserProps) => {
  const response = useInfiniteQuery({
    queryKey: [queries.GET_ACTIONS_BY_USER, userId],
    queryFn: async ({ pageParam = 1 }) =>
      await getActionsByUser({
        filters: { userIds: [userId] },
        paging: {
          page: pageParam,
          size: pageSize,
        },
        sorts: {
          field: AccountsMsSearchAuditLogsRequestSortField.WhenTs,
          ascending: false,
        },
      }),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageParam({
        lastPage,
        allPages,
        key: "AccountsMsSearchAuditLogs",
      }),
    initialPageParam: 1,
  });
  const actions = response.data ? reducePages(response.data) : [];

  return {
    isActionsLoading: response.isLoading,
    hasNextPage: response.hasNextPage,
    fetchNextPage: response.fetchNextPage,
    actions,
    totalActions:
      response.data?.pages[0]?.AccountsMsSearchAuditLogs.pagination.total || 0,
  };
};
