import { Link } from "@tanstack/react-router";
import {
  Divider,
  Drawer,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetUserDetail } from "@/api/graphql/accounts/getUser/hooks.ts";
import { UserDetailBO } from "@/api/graphql/accounts/getUser/types.ts";
import { AccountsMsSearchUsersResponseData } from "@/api/graphql/types/graphql.ts";
import { AccountsList } from "@/components/Accounts/list/component";
import { UserActionsList } from "@/components/actions/users/component.tsx";
import { BreadcrumbItem } from "@/components/shared/Breadcrumb/types.ts";
import { Loading } from "@/components/shared/Loading";
import { useBreadCrumbItems } from "@/components/Users/details/hooks.ts";
import { useMe } from "@/hooks/session/useMe";

import { StartSupportSessionDrawer } from "./startSessionDrawer/component";
import {
  StyledArrowRight,
  StyledButtonHeaderContainer,
  StyledButtonNotifications,
  StyledCard,
  StyledCardContainer,
  StyledCardContent,
  StyledCardTitle,
  StyledContainer,
  StyledIdentity,
  StyledPageHeader,
  StyledRoundedTopRightCard,
  StyledSectionContainer,
  StyledTitle,
} from "./styles";

const getUserName = (user: UserDetailBO) =>
  user?.firstName + " " + user.lastName || "N F";

export const UserDetail: React.FC<{ id: string }> = ({ id }) => {
  const { t: tUsers } = useTranslation("users");
  const { t: tNotifications } = useTranslation("notifications");
  const { userData, isLoading } = useGetUserDetail(id);
  const { data: meData, isLoading: isMeLoading } = useMe();
  const breadcrumbItems: BreadcrumbItem[] = useBreadCrumbItems(
    userData as AccountsMsSearchUsersResponseData,
  );
  const [showStartSessionAgent, setShowStartSessionAgent] = useState(false);
  const isSuperAgent =
    meData?.AccountsMsMe.supportUserInfo.rights?.includes("SUPER_AGENT");

  if (!userData) return <div>User not found</div>;

  return (
    <StyledContainer>
      <Loading isLoading={isLoading}>
        <>
          <StyledPageHeader
            breadcrumbItems={breadcrumbItems}
            title={getUserName(userData)}
            href={""}
            rightContent={
              <StyledButtonHeaderContainer>
                <StyledButtonNotifications
                  onClick={() => {
                    setShowStartSessionAgent(true);
                  }}
                  buttonText={tUsers("users.impersonate.button")}
                  variant={"primary"}
                  isLowercase
                  size={"m"}
                />
                <Link
                  to='/users/$id/notifications'
                  params={{ id: userData.id || "" }}
                >
                  <StyledButtonNotifications
                    onClick={() => {}}
                    buttonText={tNotifications("notifications.button.name")}
                    variant={"primary"}
                    isLowercase
                    leftIcon={<StyledArrowRight />}
                    size={"m"}
                  />
                </Link>
              </StyledButtonHeaderContainer>
            }
          />
          <StyledSectionContainer>
            <StyledTitle variant='title2'>
              {tUsers("users.list.subtitle.info")}
            </StyledTitle>
            <StyledCardContainer>
              <StyledCard>
                <StyledCardTitle variant='title2'>
                  {tUsers("users.cards.user")}
                </StyledCardTitle>
                <Divider />
                <StyledIdentity
                  avatar={{
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    type: "initials",
                  }}
                  subtitle={userData.email}
                  title={getUserName(userData)}
                />
              </StyledCard>
              <StyledCard>
                <StyledCardTitle variant='title2'>
                  {" "}
                  {tUsers("users.cards.phoneNumber")}
                </StyledCardTitle>
                <Divider />
                <StyledCardContent variant='title2'>
                  {userData.phone}
                </StyledCardContent>
              </StyledCard>
              <StyledRoundedTopRightCard>
                <StyledCardTitle variant='title2'>
                  {tUsers("users.cards.jobTitle")}
                </StyledCardTitle>
                <Divider />
                <StyledCardContent variant='title2'>
                  {userData.jobTitle}
                </StyledCardContent>
              </StyledRoundedTopRightCard>
            </StyledCardContainer>
          </StyledSectionContainer>
          <AccountsList userId={id} />
          <Loading isLoading={isMeLoading}>
            <>{isSuperAgent && <UserActionsList userId={id} />}</>
          </Loading>
          <Drawer
            isOpen={showStartSessionAgent}
            onClose={() => setShowStartSessionAgent(false)}
          >
            <StartSupportSessionDrawer
              user={userData}
              onClose={() => setShowStartSessionAgent(false)}
            />
          </Drawer>
        </>
      </Loading>
    </StyledContainer>
  );
};
