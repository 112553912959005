import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import actionsEN from "@/assets/locales/en-US/actions.json";
import agentsEN from "@/assets/locales/en-US/agents.json";
import commonEN from "@/assets/locales/en-US/common.json";
import companiesEN from "@/assets/locales/en-US/companies.json";
import homePageEN from "@/assets/locales/en-US/homePage.json";
import notificationsEN from "@/assets/locales/en-US/notifications.json";
import profilesEN from "@/assets/locales/en-US/profiles.json";
import searchEN from "@/assets/locales/en-US/search.json";
import signInEN from "@/assets/locales/en-US/signIn.json";
import usersEN from "@/assets/locales/en-US/users.json";
import actionsFR from "@/assets/locales/fr-FR/actions.json";
import agentsFR from "@/assets/locales/fr-FR/agents.json";
import commonFR from "@/assets/locales/fr-FR/common.json";
import companiesFR from "@/assets/locales/fr-FR/companies.json";
import homePageFR from "@/assets/locales/fr-FR/homePage.json";
import notificationsFR from "@/assets/locales/fr-FR/notifications.json";
import profilesFR from "@/assets/locales/fr-FR/profiles.json";
import searchFR from "@/assets/locales/fr-FR/search.json";
import signInFR from "@/assets/locales/fr-FR/signIn.json";
import usersFR from "@/assets/locales/fr-FR/users.json";

export const defaultNS = "common";
const resources = {
  en: {
    agents: agentsEN,
    common: commonEN,
    companies: companiesEN,
    homePage: homePageEN,
    profiles: profilesEN,
    search: searchEN,
    signIn: signInEN,
    users: usersEN,
    notifications: notificationsEN,
    actions: actionsEN,
  },
  fr: {
    agents: agentsFR,
    common: commonFR,
    companies: companiesFR,
    homePage: homePageFR,
    profiles: profilesFR,
    search: searchFR,
    signIn: signInFR,
    users: usersFR,
    notifications: notificationsFR,
    actions: actionsFR,
  },
};

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("lng") || "en-US",
  resources,
  ns: [
    "agents",
    "common",
    "companies",
    "homePage",
    "profiles",
    "search",
    "signIn",
    "users",
    "notifications",
    "actions",
  ],
  defaultNS: defaultNS,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});
export default i18n;
