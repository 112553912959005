import {
  Divider,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGetAccountDetail } from "@/api/graphql/accounts/getAccountDocument/hooks.ts";
import { useBreadCrumbItems } from "@/components/Accounts/details/hooks.ts";
import { AgentActionsList } from "@/components/actions/agents/component.tsx";
import { Loading } from "@/components/shared/Loading";
import { PageHeader } from "@/components/shared/PageHeader";
import { UsersList } from "@/components/Users/list/component";
import { toFullName } from "@/helpers/identity/toFullName";
import { useMe } from "@/hooks/session/useMe";

import {
  StyledAddressContainer,
  StyledCard,
  StyledCardContainer,
  StyledCardContent,
  StyledCardTitle,
  StyledContainer,
  StyledIdentity,
  StyledRoundedTopRightCard,
  StyledSectionContainer,
  StyledTextContainer,
  StyledTitle,
} from "./styles";

export const AccountDetails: React.FC<{ id: string }> = ({ id }) => {
  const { t: tCompanies } = useTranslation("companies");
  const { data: meData, isLoading: isMeLoading } = useMe();
  const { companyData, isLoading } = useGetAccountDetail(id);
  const fullName = toFullName({
    firstName: companyData?.userFirstName,
    lastName: companyData?.userLastName,
  });
  const fullNameAvatar =
    companyData?.userFirstName + " " + companyData?.userLastName || "N F";
  const avatarData = {
    firstName: companyData?.userFirstName,
    lastName: companyData?.userLastName,
    type: "initials",
  };
  const addressDetail = `${companyData?.zipCode} ${companyData?.city} - ${companyData?.country}`;
  const breadcrumbItems = useBreadCrumbItems(companyData?.name);
  const isSuperAgent =
    meData?.AccountsMsMe.supportUserInfo.rights?.includes("SUPER_AGENT");

  return (
    <StyledContainer>
      <Loading isLoading={isLoading}>
        <>
          {companyData?.name && (
            <PageHeader
              breadcrumbItems={breadcrumbItems}
              title={companyData?.name}
              href={""}
            />
          )}
          <StyledSectionContainer>
            <StyledTitle variant='title2'>
              {tCompanies("companies.list.subtitles.info")}
            </StyledTitle>
            <StyledCardContainer>
              <StyledCard>
                <StyledCardTitle variant='title2'>
                  {tCompanies("companies.cards.companyName")}
                </StyledCardTitle>
                <Divider />
                <StyledCardContent variant='title2'>
                  {companyData?.name}
                </StyledCardContent>
              </StyledCard>
              <StyledRoundedTopRightCard>
                <StyledCardTitle variant='title2'>
                  {tCompanies("companies.cards.accountOwner")}
                </StyledCardTitle>
                <Divider />
                <StyledIdentity
                  avatar={avatarData}
                  subtitle={companyData?.userEmail}
                  title={fullNameAvatar}
                />
              </StyledRoundedTopRightCard>
              <StyledCard>
                <StyledCardTitle variant='title2'>
                  {tCompanies("companies.cards.accountNumber")}
                </StyledCardTitle>
                <Divider />
                <StyledCardContent variant='title2'>
                  {companyData?.number}
                </StyledCardContent>
              </StyledCard>
              <StyledCard>
                <StyledCardTitle variant='title2'>
                  {tCompanies("companies.cards.officeAddress")}
                </StyledCardTitle>
                <Divider />
                <StyledAddressContainer>
                  <StyledTextContainer>
                    <Text
                      variant='text2'
                      dataTestId='order__order-ledgers-confirmation-company-name_text'
                    >
                      {companyData?.name}
                    </Text>
                    <Text variant='text2'>{fullName}</Text>
                  </StyledTextContainer>
                  <StyledTextContainer>
                    <Text variant='text2'>{companyData?.address}</Text>
                    <Text variant='text2'>{addressDetail}</Text>
                  </StyledTextContainer>
                </StyledAddressContainer>
              </StyledCard>
            </StyledCardContainer>
          </StyledSectionContainer>
          <UsersList accountId={id} />
          <Loading isLoading={isMeLoading}>
            <>{isSuperAgent && <AgentActionsList accountId={id} />}</>
          </Loading>
        </>
      </Loading>
    </StyledContainer>
  );
};
