import { graphql } from "@/api/graphql/types/gql.ts";

export const GetActionsByUserQuery = graphql(`
  query GetActionsByUserQuery($request: AccountsMsSearchAuditLogsRequest) {
    AccountsMsSearchAuditLogs(request: $request) {
      data {
        id
        action
        AgentIdData {
          firstName
          lastName
          id
          AccessIdData {
            igg
          }
        }
        whenTs
      }
      pagination {
        size
        page
        total
      }
    }
  }
`);
