export const namedOperations = {
  SearchAccountsMsMe: "SearchAccountsMsMe",
};

export const queries = {
  GET_SEARCH_AGENTS: "GET_SEARCH_AGENTS",
  GET_COUNT_AGENTS_BY_STATUS: "GET_COUNT_AGENTS_BY_STATUS",
  INVITE_SUPPORT_USER: "INVITE_SUPPORT_USER",
  GET_USER_COMPANIES: "GET_USER_COMPANIES",
  GET_NOTIFICATIONS_BY_USER: "GET_NOTIFICATIONS_BY_USER",
  GET_ACTIONS_BY_USER: "GET_ACTIONS_BY_USER",
  GET_ACTIONS_BY_ACCOUNT: "GET_ACTIONS_BY_ACCOUNT",
  GET_ACCOUNT_DETAIL: "GET_ACCOUNT_DETAIL",
  GET_USER_DETAIL: "GET_USER_DETAIL",
  GET_ACCOUNT_USERS: "GET_ACCOUNT_USERS",
};
