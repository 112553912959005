import { UserDetailBO } from "@/api/graphql/accounts/getUser/types.ts";
import { AccountsMsSearchUsersResponseData } from "@/api/graphql/types/graphql.ts";

export const toUserDetails = (
  userDetail: AccountsMsSearchUsersResponseData | null,
): UserDetailBO => ({
  id: userDetail?.id,
  email: userDetail?.email,
  locale: userDetail?.locale,
  timezone: userDetail?.timezone,
  firstName: userDetail?.firstName,
  lastName: userDetail?.lastName,
  phone: userDetail?.phone,
  phoneAlt: userDetail?.phone,
  jobTitle: userDetail?.jobTitle,
});
