import { useTheme } from "@emotion/react";
import { SelectChangeEvent } from "@mui/material";
import {
  Avatar,
  Check,
  Select,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@/components/shared/Loading";
import { useAddSnackbar } from "@/contexts/snackbar";
import { toFullName } from "@/helpers/identity/toFullName";
import { useUserCompanies } from "@/hooks/user/getUserCompanies/hooks";

import { useStartSupportSessionUtils } from "../hooks";
import {
  StyledAlertContainer,
  StyledAlertContent,
  StyledAlertDescription,
  StyledAlertIcon,
  StyledAlertIconContainer,
  StyledAlertTitle,
  StyledButton,
  StyledButtonsContainer,
  StyledDivision,
  StyledDrawerContainer,
  StyledFormContainer,
  StyledInformation,
  StyledInformationsContainer,
  StyledName,
  StyledNameDivision,
  StyledOptionContainer,
} from "./styles";
import { Props } from "./types";

export const StartSupportSessionDrawer: FunctionComponent<Props> = ({
  user,
  onClose,
}) => {
  const { t: tUsers } = useTranslation("users");
  const { t: tCommon } = useTranslation("common");
  const theme = useTheme();
  const addSnackbar = useAddSnackbar();
  const { startSession } = useStartSupportSessionUtils(
    addSnackbar,
    tUsers,
    onClose,
  );
  const { userCompanies: listOfCompanies, isLoading } = useUserCompanies(
    user.id || "",
  );
  const accountsOptions = listOfCompanies?.map((account) => ({
    value: account.AccountIdData?.id,
    label: (
      <StyledOptionContainer>
        <div>{account.AccountIdData?.number}</div>
        <Text variant='sub3'>{account.AccountIdData?.name}</Text>
      </StyledOptionContainer>
    ),
  }));
  const [selectedAccount, setSelectedAccount] = useState<string | null>(
    accountsOptions && accountsOptions.length > 1
      ? null
      : accountsOptions?.[0].value || null,
  );

  return (
    <StyledDrawerContainer>
      {user && !isLoading && (
        <Loading isLoading={isLoading}>
          <>
            <StyledInformationsContainer>
              <Text variant='title5'>
                {tUsers("users.impersonate.drawer.title")}
              </Text>
              <StyledInformation>
                <Avatar
                  type='initials'
                  firstName={user.firstName || ""}
                  lastName={user.lastName || ""}
                  size='m'
                />
                <StyledNameDivision>
                  <StyledName>
                    <Text variant='title4'>{toFullName(user)}</Text>
                  </StyledName>
                  <StyledDivision>
                    <Text variant='text2'>{user.email}</Text>
                  </StyledDivision>
                </StyledNameDivision>
              </StyledInformation>
            </StyledInformationsContainer>
            <StyledFormContainer>
              <Select
                type='options'
                value={selectedAccount}
                onChange={(e: SelectChangeEvent) => {
                  setSelectedAccount(e.target.value);
                }}
                helperText={tUsers(
                  "users.impersonate.drawer.selectAccount.description",
                )}
                label={tUsers(
                  "users.impersonate.drawer.selectAccount.placeholder",
                )}
                options={accountsOptions}
                isDisabled={accountsOptions && accountsOptions?.length === 1}
              />

              <StyledAlertContainer>
                <StyledAlertIconContainer>
                  <StyledAlertIcon width={30} height={30} />
                </StyledAlertIconContainer>
                <StyledAlertContent>
                  <StyledAlertTitle variant='title1'>
                    {tUsers("users.impersonate.drawer.informationsCard.noted")}
                  </StyledAlertTitle>
                  <StyledAlertDescription variant='text1' fontStyle='italic'>
                    {tUsers(
                      "users.impersonate.drawer.informationsCard.information1",
                    )}
                  </StyledAlertDescription>
                  <StyledAlertDescription variant='text1' fontStyle='italic'>
                    {tUsers(
                      "users.impersonate.drawer.informationsCard.information2",
                    )}
                  </StyledAlertDescription>
                </StyledAlertContent>
              </StyledAlertContainer>
              <StyledButtonsContainer>
                <StyledButton
                  variant='secondary'
                  isLowercase
                  buttonText={tCommon("common.label.cancel")}
                  onClick={() => {
                    onClose();
                  }}
                />
                <StyledButton
                  variant='primary'
                  type='submit'
                  isLowercase
                  disabled={!selectedAccount}
                  leftIcon={
                    <Check color={theme.palette.white} width={24} height={24} />
                  }
                  buttonText={tUsers("users.impersonate.drawer.startButton")}
                  onClick={() =>
                    selectedAccount &&
                    startSession({
                      userId: user.id || "",
                      accountId: selectedAccount,
                    })
                  }
                />
              </StyledButtonsContainer>
            </StyledFormContainer>
          </>
        </Loading>
      )}
    </StyledDrawerContainer>
  );
};
