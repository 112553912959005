import { capitalize } from "@mui/material";
import {
  BubbleText,
  Search,
  Subtract,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ACCOUNT_USERS_PER_PAGE } from "@/api/graphql/accounts/getAccountUsers/constants.ts";
import { useGetAccountUsers } from "@/api/graphql/accounts/getAccountUsers/hooks.ts";
import { getUserRoleDisplayedName } from "@/components/Accounts/list/helper";
import { EmptyDataInDB } from "@/components/shared/EmptyComponent";
import { Loading } from "@/components/shared/Loading";
import { RolesProfileIcon } from "@/components/shared/RolesProfileIcon";
import { isRolesProfileCode } from "@/components/shared/RolesProfileIcon/types";
import { useFormattedDate } from "@/hooks/i18n/useFormatDate";
import { ProfileType } from "@/types/profile.ts";
import { iconProps } from "@/types/theme.ts";

import {
  StyledButton,
  StyledButtonContainer,
  StyledCell,
  StyledCellHeaderText,
  StyledCellText,
  StyledCellWithEndArrow,
  StyledContainer,
  StyledCurrentCountOnTotalContainer,
  StyledCurrentCountOnTotalText,
  StyledCustomTableContainer,
  StyledDivider,
  StyledRightIcon,
  StyledRoundedLeftContainer,
  StyledRowContainerLink,
  StyledSearchField,
  StyledSearchFieldContainer,
  StyledTable,
  StyledTableHeader,
  StyledTitle,
} from "./styles";

export const UsersList: React.FC<{ accountId: string }> = ({ accountId }) => {
  const { t: tCompanies } = useTranslation("companies");
  const { t: tProfiles } = useTranslation("profiles");
  const { t: tCommon } = useTranslation("common");
  const [searchTerm, setSearchTerm] = useState("");
  const {
    data: accountUsers,
    fetchNextPage,
    hasNextPage,
    isLoading,
    totalUsers,
  } = useGetAccountUsers({ accountId, searchTerm });
  const formattedDate = useFormattedDate();

  return (
    <StyledContainer>
      <>
        <StyledRoundedLeftContainer>
          <StyledTitle variant='title2'>
            {tCompanies("companies.list.subtitles.list")}
          </StyledTitle>
          <StyledDivider />
          <StyledSearchFieldContainer>
            <StyledSearchField
              dataTestId='text-field'
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={tCompanies("companies.list.searchPlaceholder")}
              startIcon={<Search width={18} height={18} />}
              value={searchTerm}
            />
          </StyledSearchFieldContainer>
          <StyledCustomTableContainer>
            <StyledTableHeader>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.email")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.name")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.accountProfile")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.status")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.createdTs")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.lastConnection")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {""}
                </StyledCellHeaderText>
              </StyledCell>
            </StyledTableHeader>

            <StyledTable>
              {accountUsers && accountUsers.length > 0 ? (
                accountUsers.map((accountUser) => (
                  <StyledRowContainerLink
                    to={`/users/${accountUser.userId}`}
                    key={accountUser.id}
                  >
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {accountUser.userEmail}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {`${accountUser?.userFirstName} ${accountUser?.userLastName}`}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      {isRolesProfileCode(
                        accountUser?.Subscriptions?.[0].roleCode ?? "",
                      ) ? (
                        <RolesProfileIcon
                          code={
                            (accountUser?.Subscriptions?.[0]
                              .roleCode as ProfileType) ?? ""
                          }
                          size={28}
                        />
                      ) : null}
                      <StyledCellText variant='text2'>
                        {getUserRoleDisplayedName({
                          code: accountUser?.Subscriptions?.[0].roleCode,
                          name: accountUser?.Subscriptions?.[0].roleName,
                          t: tProfiles,
                        })}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        <BubbleText
                          text={capitalize(
                            tCompanies(
                              `companies.list.status.${accountUser.status}.label`.toLowerCase(),
                            ),
                          )}
                          variant={
                            accountUser.status.toLowerCase() === "active"
                              ? "green"
                              : "grey"
                          }
                        />
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {formattedDate(accountUser.userCreatedTs)}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {formattedDate(accountUser.lastUsedTs)}
                      </StyledCellText>
                    </StyledCell>

                    <StyledCellWithEndArrow>
                      <Text variant='text2'>
                        <StyledRightIcon />
                      </Text>
                    </StyledCellWithEndArrow>
                  </StyledRowContainerLink>
                ))
              ) : (
                <EmptyDataInDB principalText={tCommon("common.noData")} />
              )}
            </StyledTable>
          </StyledCustomTableContainer>
          <Loading isLoading={isLoading}>
            {hasNextPage ? (
              <StyledButtonContainer>
                <StyledButton
                  leftIcon={<Subtract {...iconProps} />}
                  variant='secondary'
                  buttonText={tCommon("common.pagination.nextPage", {
                    pageSize: ACCOUNT_USERS_PER_PAGE,
                  })}
                  isLowercase={true}
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage}
                ></StyledButton>
              </StyledButtonContainer>
            ) : null}
          </Loading>
          <StyledCurrentCountOnTotalContainer>
            <StyledCurrentCountOnTotalText variant='sub3'>
              {tCompanies("companies.list.namesWithCurrentCountOnTotal.name", {
                count: accountUsers?.length ?? 0,
                total: totalUsers,
              })}
            </StyledCurrentCountOnTotalText>
          </StyledCurrentCountOnTotalContainer>
        </StyledRoundedLeftContainer>
      </>
    </StyledContainer>
  );
};
