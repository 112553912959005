import { capitalize } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { BubbleText } from "@totalenergiescode/mobility-business-rev-design-system";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { GetUserQuery } from "@/api/graphql/accounts/getUser/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { useGetNotificationsByUser } from "@/api/graphql/notifications/hooks.ts";
import { mapNotificationsFromGraphQL } from "@/api/graphql/notifications/mappers.ts";
import { GetNotificationsByUserQueryQuery } from "@/api/graphql/types/graphql.ts";
import { StyledCellText } from "@/components/Agents/List";
import { NOTIFICATION_PER_PAGE } from "@/components/Notifications/list/constants.ts";
import {
  getCompanyName,
  getStatusAndColor,
} from "@/components/Notifications/list/helper.ts";
import { StyledNoDataText } from "@/components/Notifications/list/styles.ts";
import { useFormattedDate } from "@/hooks/i18n/useFormatDate";
export const useNotificationTableData = (userId: string) => {
  const { t: tCompanies } = useTranslation("companies");
  const { t: tNotifications } = useTranslation("notifications");
  const formattedDate = useFormattedDate();
  const {
    data,
    isLoading: isNotificationsLoading,
    totalNotifications,
    hasNextPage,
    fetchNextPage,
  } = useGetNotificationsByUser({ pageSize: NOTIFICATION_PER_PAGE, userId });
  const notifications = useMemo(
    () =>
      data?.pages?.reduce(
        (acc, current) => {
          const data = current?.NotificationsMsSearchNotifications.data;

          if (!data) {
            return acc;
          }

          return [...acc, ...data];
        },
        [] as NonNullable<
          GetNotificationsByUserQueryQuery["NotificationsMsSearchNotifications"]["data"]
        >,
      ),
    [data],
  );
  const listOfNotifications = mapNotificationsFromGraphQL(notifications);
  const columns = useMemo(
    () => [
      {
        columnName: tCompanies("companies.list.title"),
        displayedByDefault: true,
      },
      {
        columnName: tNotifications("notifications.table.object"),
        displayedByDefault: true,
      },
      {
        columnName: tNotifications("notifications.table.attachment"),
        displayedByDefault: true,
      },
      {
        columnName: tNotifications("notifications.table.status"),
        displayedByDefault: true,
      },
      {
        columnName: tNotifications("notifications.table.dateSent"),
        displayedByDefault: true,
      },
    ],
    [tCompanies, tNotifications],
  );
  const rows = useMemo(() => {
    if (!listOfNotifications) return [];

    return listOfNotifications.map((notification) => {
      const { translatedStatus, color } = getStatusAndColor({
        status: notification?.status,
        failedReason: notification?.failedReason,
        tNotifications,
      });
      const getCompanyNameCall = getCompanyName({
        code: notification.accountNumber,
        name: notification.accountName,
        tNotifications,
      });
      const companyName =
        notification.accountNumber === "" && notification.accountName === "" ? (
          <StyledNoDataText variant={"text1"}>
            {getCompanyNameCall}
          </StyledNoDataText>
        ) : (
          getCompanyNameCall
        );

      return {
        data: {
          account: companyName,
          subject: notification?.subject,
          attachments:
            notification?.hasAttachments === false
              ? tNotifications("notifications.attachments.without")
              : tNotifications("notifications.attachments.with"),
          status: (
            <StyledCellText variant='text2'>
              <BubbleText text={capitalize(translatedStatus)} variant={color} />
            </StyledCellText>
          ),
          dateSent: formattedDate(notification?.createdTs),
        },
        key: notification.id,
      };
    });
  }, [listOfNotifications, tNotifications, formattedDate]);
  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: ["user", userId],
    queryFn: async () =>
      getGraphqlClient().request(GetUserQuery, {
        request: { filters: { ids: [userId] } },
      }),
  });
  const user = userData?.AccountsMsSearchUsers?.data
    ? {
        firstName: userData.AccountsMsSearchUsers?.data?.[0].firstName,
        lastName: userData.AccountsMsSearchUsers?.data?.[0].lastName,
        email: userData.AccountsMsSearchUsers?.data?.[0].email,
      }
    : null;

  return {
    columns,
    rows,
    totalNotifications,
    isUserLoading,
    isNotificationsLoading,
    user,
    hasNextPage,
    fetchNextPage,
  };
};

export const useBreadCrumbItems = (fullname: string) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tNotifications } = useTranslation("notifications");

  return [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: fullname || "",
      path: "/users/$id" as const,
    },
    {
      title: tNotifications("notifications.title"),
      path: undefined,
    },
  ];
};
