import { Container, styled } from "@mui/material";
import {
  Divider,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { RoundedTopLeftContainer } from "@/components/containers/RoundedTopLeftContainer";
import { ControlledTextField } from "@/components/shared/ControlledTextField";

export const StyledSection = styled("div")``;
export const StyledDivider = styled(Divider)`
  max-width: 700px;
`;
export const StyledLightGreyText = styled(Text)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;
export const StyledControlledTextField = styled(ControlledTextField)`
  margin-inline: 15px;
  padding: 30px;
`;

export const StyledForm = styled("form")``;

export const StyledDrawerInnerContainer = styled("div")`
  padding-block: 30px;
`;
export const StyledRoundedTopLeftContainer = styled(RoundedTopLeftContainer)`
  margin-top: 20px;
`;

export const StyledTopContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0;
`;

export const StyledContainer = styled(Container)`
  padding-block: 15px;
  max-width: 700px;
`;

export const StyledInputContainer = styled("div")<{
  fixedWidth?: boolean;
}>`
  padding-block: 15px;
  max-width: ${({ fixedWidth }) => (fixedWidth ? "320px" : "100%")};
`;

export const StyledSelectContainer = styled("div")`
  margin-top: 24px;
  max-width: 675px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 10px;
  border-left: 10px solid ${({ theme }) => theme.palette.primary[500]};
  padding: 10px;
`;

export const StyledButtonContainer = styled("div")`
  padding-block: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
