import styled from "@emotion/styled";
import {
  ArrowRight,
  CustomTable,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

export const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const StyledTitle = styled(Text)`
  padding: 24px 0 24px 34px;
`;
export const StyledTableHeader = styled("div")``;
export const StyledTable = styled("div")``;
export const StyledCell = styled("div")``;
export const StyledCellWithEndArrow = styled("div")``;
export const StyledRightIcon = styled(ArrowRight)``;
export const StyledRowContainer = styled("div")``;
export const StyledCellHeaderText = styled(Text)``;
export const StyledCellText = styled(Text)``;
export const StyledCustomTable = styled(CustomTable)``;
export const StyledCurrentCountOnTotalText = styled(Text)``;
export const StyledCurrentCountOnTotalContainer = styled("div")``;

export const StyledRoundedLeftContainer = styled("div")`
  padding-inline: 34px;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.palette.grey[100]} 0%,
    ${({ theme }) => theme.palette.background.paper} 30%
  );
  border-top-left-radius: 40px;
`;

export const StyledCustomTableContainer = styled("div")`
  padding-inline: 34px;

  .actions-user-table {
    margin-top: -10px;
    margin-left: 0;
  }

  ${StyledTableHeader} {
    display: grid;
    grid-template-columns: repeat(6, 1fr) 30px;
    width: 100%;
    min-width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }

  ${StyledTable} {
  }

  ${StyledRowContainer} {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(6, 1fr) 30px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }

  ${StyledCell} {
    padding-left: 14px;
    min-height: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-block: 14px;

    ${StyledCellHeaderText} {
      color: ${({ theme }) => theme.palette.grey[700]};
      font-weight: 700;
    }

    ${StyledCellText} {
      color: ${({ theme }) => theme.palette.grey[900]};
    }
  }

  ${StyledCellWithEndArrow} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${StyledRightIcon} {
      color: ${({ theme }) => theme.palette.primary[500]};
      width: 26px;
      height: auto;
    }
  }
  ${StyledCurrentCountOnTotalText} {
    color: ${({ theme }) => theme.palette.grey[500]};
    text-align: center;
  }

  ${StyledCurrentCountOnTotalContainer} {
    padding-block: 14px;
  }
`;
