import { InfiniteData } from "@tanstack/react-query";

import { AgentActions } from "@/api/graphql/actions/agent/types.ts";
import { ActionBO } from "@/api/graphql/actions/types.ts";
import { GetActionsByAccountQueryQuery } from "@/api/graphql/types/graphql.ts";
import { convertTimestamp } from "@/utils/date";

export const fromAgentActionsToDisplayableAgentActions = (
  rawActions: AgentActions,
): Array<ActionBO> =>
  rawActions?.map((rawAction) => {
    const { dateString, timeString } = convertTimestamp(
      rawAction.whenTs,
      "DD.MM.YYYY",
      "HH[h]mm",
    );

    return {
      id: rawAction.id,
      date: dateString,
      time: timeString,
      name: rawAction.action,
      agent: {
        id: rawAction.AgentIdData?.id || "",
        firstName: rawAction.AgentIdData?.firstName || "",
        lastName: rawAction.AgentIdData?.lastName || "",
        igg: rawAction.AgentIdData?.AccessIdData?.igg || "",
      },
      client: {
        firstName: rawAction.UserIdData?.firstName || "",
        lastName: rawAction.UserIdData?.lastName || "",
      },
    };
  });

export const reducePages = (
  queryResult: InfiniteData<GetActionsByAccountQueryQuery>,
): ActionBO[] => {
  if (!queryResult.pages) {
    return [];
  }

  const allAgentActions = queryResult.pages.reduce<AgentActions>(
    (acc, current) => {
      const data = current.AccountsMsSearchAuditLogs.data;

      if (data) {
        return [...acc, ...data];
      }

      return acc;
    },
    [],
  );

  return fromAgentActionsToDisplayableAgentActions(allAgentActions);
};
