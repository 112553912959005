import React from "react";
import { useTranslation } from "react-i18next";

import { ACTIONS_PER_PAGE } from "@/api/graphql/actions/constants.ts";
import { useActionTableData } from "@/components/actions/agents/hooks.tsx";
import { EmptyDataForThisFilter } from "@/components/shared/EmptyComponent";
import { Loading } from "@/components/shared/Loading";

import {
  StyledContainer,
  StyledCurrentCountOnTotalContainer,
  StyledCurrentCountOnTotalText,
  StyledCustomTable,
  StyledCustomTableContainer,
  StyledRoundedLeftContainer,
  StyledTitle,
} from "./styles";

export const AgentActionsList: React.FC<{ accountId: string }> = ({
  accountId,
}) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tActions } = useTranslation("actions");
  const {
    columns,
    rows,
    fetchNextPage,
    hasNextPage,
    isActionsLoading,
    totalActions,
  } = useActionTableData(accountId);
  const total = Number(totalActions);

  return (
    <StyledContainer>
      <>
        <StyledRoundedLeftContainer>
          <StyledTitle variant='title2'>
            {tActions("actions.table.header.title.account")}
          </StyledTitle>
          <Loading isLoading={isActionsLoading || false}>
            <StyledCustomTableContainer>
              <StyledCustomTable
                rows={rows}
                columns={columns}
                loadMoreButton={{
                  dataTestId: "actions__list_load-more-button",
                  label: tCommon("common.pagination.nextPage", {
                    pageSize: ACTIONS_PER_PAGE,
                  }),
                  onClick: fetchNextPage,
                  isDisabled: !hasNextPage,
                }}
                className={"actions-account-table"}
              />
              {total === 0 && (
                <EmptyDataForThisFilter
                  principalText={tActions("actions.table.body.noData")}
                />
              )}
              <StyledCurrentCountOnTotalContainer>
                <StyledCurrentCountOnTotalText variant='sub3'>
                  {rows.length}/
                  {tActions("actions.table.footer.name", {
                    count: total,
                  })}
                </StyledCurrentCountOnTotalText>
              </StyledCurrentCountOnTotalContainer>
            </StyledCustomTableContainer>
          </Loading>
        </StyledRoundedLeftContainer>
      </>
    </StyledContainer>
  );
};
