import { useQuery } from "@tanstack/react-query";

import { getUserDetail } from "@/api/graphql/accounts/getUser/api.ts";
import { toUserDetails } from "@/api/graphql/accounts/getUser/mappers.ts";
import { queries } from "@/api/graphql/constants.ts";
import { AccountsMsSearchUsersResponseData } from "@/api/graphql/types/graphql.ts";

export const useGetUserDetail = (userId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: [queries.GET_USER_DETAIL, userId],
    queryFn: () => {
      try {
        return getUserDetail({
          filters: { ids: [userId] },
        });
      } catch {
        return;
      }
    },
  });
  const userDetail = data?.AccountsMsSearchUsers
    .data?.[0] as AccountsMsSearchUsersResponseData | null;

  return { userData: { ...toUserDetails(userDetail) }, isLoading };
};
