import { BubbleTextProps } from "@totalenergiescode/mobility-business-rev-design-system";
import { match } from "ts-pattern";

import { CompanyNameProps } from "@/api/graphql/notifications/types.ts";
import {
  NotificationsMsSearchNotificationsResponseDataFailedReason,
  NotificationsMsSearchNotificationsResponseDataStatus,
} from "@/api/graphql/types/graphql.ts";

export const getStatusAndColor = ({
  status,
  failedReason,
  tNotifications,
}: {
  status: NotificationsMsSearchNotificationsResponseDataStatus;
  failedReason: NotificationsMsSearchNotificationsResponseDataFailedReason | "";
  tNotifications: (key: string) => string;
}): { translatedStatus: string; color: BubbleTextProps["variant"] } =>
  match({ status, failedReason })
    .with(
      { status: NotificationsMsSearchNotificationsResponseDataStatus.New },
      () => ({
        translatedStatus: tNotifications("notifications.status.NEW"),
        color: "orange" as BubbleTextProps["variant"],
      }),
    )
    .with(
      {
        status: NotificationsMsSearchNotificationsResponseDataStatus.Handled,
        failedReason: "",
      },
      () => ({
        translatedStatus: tNotifications("notifications.status.HANDLED"),
        color: "green" as BubbleTextProps["variant"],
      }),
    )
    .otherwise(() => ({
      translatedStatus: tNotifications("notifications.status.ERROR"),
      color: "red" as BubbleTextProps["variant"],
    }));

export const getCompanyName = ({
  code,
  name,
  tNotifications,
}: CompanyNameProps) =>
  match({ code, name })
    .with({ code: undefined, name: undefined }, () =>
      tNotifications("notifications.dataNotAvailable"),
    )
    .with({ code: undefined }, () => `${name}`)
    .with({ name: undefined }, () => `${code}`)
    .otherwise(() => `${code} - ${name}`);
