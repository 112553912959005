import { useRouter } from "@tanstack/react-router";
import { KeyboardEvent } from "react";

import { CompanyDataType, EntityType, UserDataType } from "./types";

export const useSearchAutocomplete = ({
  highlightedOption,
  setHighlightedOption,
}: {
  highlightedOption: CompanyDataType | UserDataType | null;
  setHighlightedOption: (value: CompanyDataType | UserDataType | null) => void;
}) => {
  const router = useRouter();
  const handleKeyDown = (
    event: KeyboardEvent<HTMLDivElement> & {
      defaultMuiPrevented?: boolean | undefined;
    },
  ) => {
    if (event.key === "Enter" && highlightedOption) {
      // Rediriger vers le lien surligné
      const url =
        highlightedOption.type === EntityType.User
          ? `/users/${highlightedOption.id}`
          : `/accounts/${highlightedOption.id}`;

      router.navigate({ to: url });
    }
  };
  const handleHighlightChange = (
    option: CompanyDataType | UserDataType | null,
  ) => {
    if (option && option.id !== highlightedOption?.id) {
      setHighlightedOption(option);
    }
  };

  return {
    handleKeyDown,
    handleHighlightChange,
  };
};
