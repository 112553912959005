import { GetActionsByAccountQuery } from "@/api/graphql/actions/agent/queries.ts";
import { ACTIONS_PER_PAGE } from "@/api/graphql/actions/constants.ts";
import { GetActionsRequest } from "@/api/graphql/actions/types.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { GetActionsByAccountQueryQuery } from "@/api/graphql/types/graphql.ts";

export const getActionsByAccount = async ({
  filters,
  paging,
  sorts,
}: GetActionsRequest) => {
  const page = paging?.page || 1;
  const size = paging?.size || ACTIONS_PER_PAGE;
  const accountIds = filters?.accountIds || "";

  return await getGraphqlClient().request<GetActionsByAccountQueryQuery>(
    GetActionsByAccountQuery,
    {
      request: {
        filters: {
          accountIds,
        },
        paging: {
          page,
          size,
        },
        sorts,
      },
    },
  );
};
