import { useQuery } from "@tanstack/react-query";

import { AccountsMsSearchPrivilegesQuery } from "@/api/graphql/accounts/getUserCompanies/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { queries } from "@/api/graphql/constants.ts";
import { AccountsMsSearchPrivilegesRequest } from "@/api/graphql/types/graphql.ts";

const getUserCompanies = (request: AccountsMsSearchPrivilegesRequest) => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(AccountsMsSearchPrivilegesQuery, { request });
};

export const useUserCompanies = (userId: string, searchTerm?: string) => {
  const filters = {
    userId: userId,
    query: {
      account: searchTerm?.length && searchTerm?.length > 2 ? searchTerm : "",
    },
  };
  const { data, isLoading } = useQuery({
    queryKey: [queries.GET_USER_COMPANIES, { filters }],
    queryFn: async () => {
      try {
        return await getUserCompanies({ filters });
      } catch (_err) {
        return;
      }
    },
  });
  const userCompanies = data?.AccountsMsSearchPrivileges.data?.map(
    (accountDetail) => accountDetail,
  );

  return { userCompanies, isLoading };
};
