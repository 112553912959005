import { ACTIONS_PER_PAGE } from "@/api/graphql/actions/constants.ts";
import { GetActionsRequest } from "@/api/graphql/actions/types.ts";
import { GetActionsByUserQuery } from "@/api/graphql/actions/user/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { GetActionsByUserQueryQuery } from "@/api/graphql/types/graphql.ts";

export const getActionsByUser = async ({
  filters,
  paging,
  sorts,
}: GetActionsRequest) => {
  const page = paging?.page || 1;
  const size = paging?.size || ACTIONS_PER_PAGE;
  const userIds = filters?.userIds || "";

  return await getGraphqlClient().request<GetActionsByUserQueryQuery>(
    GetActionsByUserQuery,
    {
      request: {
        filters: {
          userIds,
        },
        paging: {
          page,
          size,
        },
        sorts,
      },
    },
  );
};
