import { GetAccountUsersQuery } from "@/api/graphql/accounts/getAccountUsers/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { AccountsMsSearchPrivilegesRequest } from "@/api/graphql/types/graphql.ts";

export const getAccountUsers = ({
  filters,
  paging,
}: AccountsMsSearchPrivilegesRequest) => {
  const currentPage = paging?.page;
  const pageSize = paging?.size;
  const accountId = filters?.accountId;
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(GetAccountUsersQuery, {
    request: {
      paging: {
        page: currentPage,
        size: pageSize,
      },
      filters: {
        accountId,
        query: {},
      },
    },
  });
};
