import styled from "@emotion/styled";
import { Link } from "@tanstack/react-router";
import {
  ArrowRight,
  CustomTable,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { PageHeader } from "@/components/shared/PageHeader";

export const StyledTableHeader = styled("div")``;
export const StyledTable = styled("div")``;
export const StyledCell = styled("div")``;
export const StyledCellWithEndArrow = styled("div")``;
export const StyledRightIcon = styled(ArrowRight)``;
export const StyledRowContainerLink = styled(Link)`
  cursor: pointer;
`;
export const StyledCellHeaderText = styled(Text)``;
export const StyledCellText = styled(Text)``;

export const StyledCustomTableContainer = styled("div")`
  padding-inline: 34px;

  ${StyledTableHeader} {
    display: grid;
    grid-template-columns: repeat(6, 1fr) 30px;
    width: 100%;
    min-width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
  ${StyledTable} {
  }

  ${StyledRowContainerLink} {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(6, 1fr) 30px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }

  ${StyledCell} {
    padding-left: 14px;
    min-height: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-block: 14px;

    ${StyledCellHeaderText} {
      color: ${({ theme }) => theme.palette.grey[700]};
      font-weight: 700;
    }

    ${StyledCellText} {
      color: ${({ theme }) => theme.palette.grey[900]};
    }
  }

  ${StyledCellWithEndArrow} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${StyledRightIcon} {
      color: ${({ theme }) => theme.palette.primary[500]};
      width: 26px;
      height: auto;
    }
  }
`;

export const StyledCurrentCountOnTotalText = styled(Text)`
  color: ${({ theme }) => theme.palette.grey[500]};
  text-align: center;
`;

export const StyledCurrentCountOnTotalContainer = styled("div")`
  padding-block: 14px;
`;

export const StyledEmailText = styled(Text)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.grey[900]};
  margin-top: 20px;
`;

export const StyledCardTitle = styled(Text)`
  font-family: "Nunito", sans-serif;
  line-height: 1;
  color: ${(props) => props.theme.palette.grey[500]};
  padding: 0 0 16px 0;
`;

export const StyledCard = styled("div")`
  padding: 24px;
  background-color: ${(props) => props.theme.palette.grey[50]};
  border-radius: 8px;
`;
export const StyledCardContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  padding: 0 68px 34px 34px;
`;

export const StyledPageHeader = styled(PageHeader)`
  justify-content: space-between;
  padding: 0 40px 20px 40px;
`;

export const StyledCustomTable = styled(CustomTable)``;

export const StyledNoDataText = styled(Text)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;
