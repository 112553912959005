import { InfiniteData } from "@tanstack/react-query";

import { GetAccountUsersQuery } from "@/api/graphql/types/graphql.ts";

import { AccountUsersDetail } from "./types";

export const toAccountUsersBO = (
  queryResponse: InfiniteData<GetAccountUsersQuery | undefined>,
): Array<AccountUsersDetail> => {
  if (queryResponse) {
    const allUsersFromAllPages = queryResponse.pages.reduce<
      Array<AccountUsersDetail>
    >((acc, page) => {
      const searchUsersOnCurrentPage =
        page?.AccountsMsSearchPrivileges.data &&
        page?.AccountsMsSearchPrivileges.data.length > 0
          ? page.AccountsMsSearchPrivileges.data.map<AccountUsersDetail>(
              (accountUser) => ({
                id: accountUser.id,
                lastUsedTs: accountUser.lastUsedTs,
                status: accountUser.status,
                userId: accountUser.UserIdData?.id || "",
                userFirstName: accountUser.UserIdData?.firstName || "",
                userLastName: accountUser.UserIdData?.lastName || "",
                userEmail: accountUser.UserIdData?.email || "",
                userCreatedTs: accountUser.UserIdData?.createdTs || 0,
                Subscriptions:
                  accountUser.Subscriptions?.map((subscription) => ({
                    roleName: subscription?.RolesProfileIdData?.name || "",
                    roleCode: subscription?.RolesProfileIdData?.code || "",
                  })) || [],
              }),
            )
          : [];

      if (searchUsersOnCurrentPage.length > 0) {
        acc.push(...searchUsersOnCurrentPage);
      }

      return acc;
    }, []);

    return allUsersFromAllPages;
  }

  return [];
};
