import styled from "@emotion/styled";
import {
  Alert,
  BubbleText,
  Button,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { DRAWER_ASSIGNMENT_WIDTH_DESKTOP } from "./constants";

export const StyledInformationsContainer = styled("div")``;
export const StyledInformation = styled("div")``;
export const StyledNameDivision = styled("div")``;
export const StyledName = styled("div")``;
export const StyledDivision = styled("div")``;
export const StyledBubbleText = styled(BubbleText)``;
export const StyledForm = styled("div")``;
export const StyledButtonsContainer = styled("div")``;
export const StyledButton = styled(Button)``;
export const StyledFormContainer = styled("div")``;
export const StyledAlertIconContainer = styled.div``;
export const StyledAlertIcon = styled(Alert)``;
export const StyledAlertContent = styled.div``;
export const StyledAlertTitle = styled(Text)``;
export const StyledAlertDescription = styled(Text)``;
export const StyledOptionContainer = styled("div")``;

export const StyledDrawerContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(5)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  height: 100%;
  overflow-y: hidden;

  @media (min-width: 800px) {
    width: ${DRAWER_ASSIGNMENT_WIDTH_DESKTOP}px;
  }
  ${StyledInformationsContainer} {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px 40px;

    ${StyledInformation} {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;

      ${StyledNameDivision} {
        display: flex;
        flex-direction: column;
        gap: 2px;

        ${StyledName} {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;

          ${StyledBubbleText} {
            height: fit-content;
          }
        }

        ${StyledDivision} {
          display: flex;
          flex-direction: row;
          gap: 8px;
        }
      }
    }
  }

  ${StyledFormContainer} {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 42px 0px 42px;
    margin-top: 25px;
    border-start-start-radius: 40px;
    background: linear-gradient(
      180deg,
      ${(props) => props.theme.palette.grey[100]} 0%,
      ${(props) => props.theme.palette.background.default} 300px,
      ${(props) => props.theme.palette.background.default} 100%
    );
    ${StyledOptionContainer} {
      display: flex;
      flex-direction: column;
    }
    ${StyledButtonsContainer} {
      display: flex;
      flex-direction: row;
      gap: 20px;

      ${StyledButton} {
        min-width: 110px;
      }
    }
  }
`;

export const StyledAlertContainer = styled("div")`
  display: flex;
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.white};
  margin-bottom: 16px;

  ${StyledAlertIconContainer} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 10px 0px 0px 10px;

    ${StyledAlertIcon} {
      color: ${({ theme }) => theme.palette.grey[300]};
    }
  }

  ${StyledAlertContent} {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 10px 12px;
    width: calc(100% - 40px);

    ${StyledAlertDescription} {
      color: ${({ theme }) => theme.palette.grey[500]};
    }
  }
`;
